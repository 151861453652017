import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import vox1 from '../../imgs/adsl/V1.png';
import vox2 from '../../imgs/adsl/V2.png';
import vox3 from '../../imgs/adsl/V3.png';
import vox4 from '../../imgs/adsl/V4.png';
import vox5 from '../../imgs/adsl/V5.png';
import vox6 from '../../imgs/adsl/V6.png';
import vox7 from '../../imgs/adsl/V7.png';
import vox8 from '../../imgs/adsl/V8.png';
import vox9 from '../../imgs/adsl/V9.png';
import vox10 from '../../imgs/adsl/V11.png';

import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>ABOUT US</h1>
	        	<p>Te-Amo Business Solutions is now a Registered Business Partner with Vox Telecommunications. Visit us online by clicking on the link below for a package that suits your needs. <Link to="https://shop.vox.co.za/teamo">VOX</Link></p><br /><br />

	        	<SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
		            <img className="rounded w-small" src={vox1} />
		            <img className="rounded w-small" src={vox2} />  
		            <img className="rounded w-small" src={vox3} />
		            <img className="rounded w-small" src={vox4} />
		            <img className="rounded w-small" src={vox5} />
		            <img className="rounded w-small" src={vox6} />
		            <img className="rounded w-small" src={vox7} />
		            <img className="rounded w-small" src={vox8} />
		            <img className="rounded w-small" src={vox9} />
		            <img className="rounded w-small" src={vox10} />
		        </SlideshowLightbox>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;