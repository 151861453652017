import { Outlet, Link } from 'react-router-dom';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';
import Nav from '../Nav';
import {useEffect} from 'react';
import Repairs1 from '../../imgs/Repairs/Data recovery.jpg';
import Repairs2 from '../../imgs/Repairs/desktop and laptop repairs.jpg';
import Repairs3 from '../../imgs/Repairs/Desktop and laptop upgardes.jpg';
import Repairs4 from '../../imgs/Repairs/Home and office setups.jpg';
import Repairs5 from '../../imgs/Repairs/pc diagnostics.jpg';
import Repairs6 from '../../imgs/Repairs/software and hardware optimization.jpg';
import Repairs7 from '../../imgs/Repairs/Software.png';

import RepairsGallery1 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0003.jpg';
import RepairsGallery2 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0004.jpg';
import RepairsGallery3 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0005.jpg';
import RepairsGallery4 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0006.jpg';
import RepairsGallery5 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0007.jpg';
import RepairsGallery6 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0008.jpg';
import RepairsGallery7 from '../../imgs/Repairs/Gallery/IMG-20210415-WA0009.jpg';

import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

const Repairs = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }
  
	useEffect(() => {
	  window.scrollTo(0, 0);
	  const descr1 = document.getElementById('descr1');
	  const descr2 = document.getElementById('descr2');
	  const descr3 = document.getElementById('descr3');
	  const descr4 = document.getElementById('descr4');
	  const descr5 = document.getElementById('descr5');
	  const descr6 = document.getElementById('descr6');
	  const descr7 = document.getElementById('descr7');
	  descr1.style.display = "none";
	  descr2.style.display = "none";
	  descr3.style.display = "none";
	  descr4.style.display = "none";
	  descr5.style.display = "none";
	  descr6.style.display = "none";
	  descr7.style.display = "none";
	}, []);

	const show1 = () => {
		const descr1 = document.getElementById('descr1');
		if(descr1.style.display == "none")
		{
			descr1.style.display = "block";
		}
		else
		{
			descr1.style.display = "none";
		}
	}

	const show2 = () => {
		const descr2 = document.getElementById('descr2');

		if(descr2.style.display == "none")
		{
			descr2.style.display = "block";
		}
		else
		{
			descr2.style.display = "none";
		}
	}

	const show3 = () => {
		const descr3 = document.getElementById('descr3');

		if(descr3.style.display == "none")
		{
			descr3.style.display = "block";
		}
		else
		{
			descr3.style.display = "none";
		}
	}

	const show4 = () => {
		const descr4 = document.getElementById('descr4');

		if(descr4.style.display == "none")
		{
			descr4.style.display = "block";
		}
		else
		{
			descr4.style.display = "none";
		}
	}

	const show5 = () => {
		const descr5 = document.getElementById('descr5');

		if(descr5.style.display == "none")
		{
			descr5.style.display = "block";
		}
		else
		{
			descr5.style.display = "none";
		}
	}

	const show6 = () => {
		const descr6 = document.getElementById('descr6');

		if(descr6.style.display == "none")
		{
			descr6.style.display = "block";
		}
		else
		{
			descr6.style.display = "none";
		}
	}


	const show7 = () => {
		const descr7 = document.getElementById('descr7');

		if(descr7.style.display == "none")
		{
			descr7.style.display = "block";
		}
		else
		{
			descr7.style.display = "none";
		}
	}
	  
	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>PC-Laptop Repairs</h1>
	        	<p>Here at <b>Te-Amo Business Solutions</b> we know that computers in the work place are a crucial tool used every day when running your business, with that said; when they have a fault it is crucial that the problem be fixed quickly and efficiently. This is where we can help. We can come out to you within 24 hours and repair the problem on site! No more worries about transporting your equipment to faraway places, and then having to come back to get them again! Alternatively, if you would prefer an engineer can visit out of office hours (when there is less disruption to your working day). We also offer weekend callouts with no extra charge. (Please be advised that callouts after 6pm will incur an extra charge). Some of the Business Services we offer include:</p><br /><br />

	        	<div className="row">
	        		<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show1()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs1}
				              alt="Next.js Logo"
				            />
				            <b>Data recovery</b>
				            <p id="descr1">Data recovery is a process of salvaging (retrieving) inaccessible, lost, corrupted, damaged or formatted data from secondary storage, removable media or files, when the data stored in them cannot be accessed in a usual way</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show2()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs2}
				              alt="Next.js Logo"
				            />
				            <b>Desktop and laptop repairs</b>
				            <p id="descr2">Computer repair is the process of identifying, troubleshooting and resolving problems and issues in a faulty computer. Computer repair is a broad field encompassing many tools, techniques and procedures used to repair computer hardware, software or network/Internet problems.</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show3()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs3}
				              alt="Next.js Logo"
				            />
				            <b>Desktop and laptop upgrades</b>
				            <p id="descr3">With computer hardware and software, an upgrade is a term that describes adding new hardware or software in a computer that improves its performance and graphics.</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show4()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs4}
				              alt="Next.js Logo"
				            />
				            <b>Home and office setups</b>
				            <p id="descr4">Installations of Pc`s , Access points , projectors , tv`s , smart boxes and many more to suite or home or office of any needs of choice.</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show5()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs5}
				              alt="Next.js Logo"
				            />
				            <b>PC computer diagnostics</b>
				            <p id="descr5">Testing of computers hardware device or software program to ensure it is working as it should be or if there are any faults that needs to be repaired or parts that needs to be replace</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show6()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs6}
				              alt="Next.js Logo"
				            />
				            <b>Software and hardware optimization</b>
				            <p id="descr6">program optimization, code optimization, or software optimization, modifying a software system to make some aspect of it work more efficiently or use fewer resources, or to speed up PC's</p>
			        	</div>
		        	</div>
		        	<div className="col-md-4">
			        	<div className="repairs-card" onClick={(e)=>show7()}>
			        		<img
				              className='repairs-img-card'
				              src={Repairs7}
				              alt="Next.js Logo"
				            />
				            <b>Software and OS software systems</b>
				            <p id="descr7">A wide range of Microsoft windows, Microsoft Office and other essential software.</p>
			        	</div>
		        	</div>
	        	</div><br /><br /><br />

	        	<h1>Custom Built PCs</h1>
	        	<br />

	        	<SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
		            <img className="rounded w-small" src={RepairsGallery1} />
		            <img className="rounded w-small" src={RepairsGallery2} />  
		            <img className="rounded w-small" src={RepairsGallery3} />
		            <img className="rounded w-small" src={RepairsGallery4} />
		            <img className="rounded w-small" src={RepairsGallery5} />
		            <img className="rounded w-small" src={RepairsGallery6} />
		            <img className="rounded w-small" src={RepairsGallery7} />
		        </SlideshowLightbox>
		        <br /><br /><br />
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default Repairs;