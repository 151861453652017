import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

const About = () => {
  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>ABOUT US</h1>
	        	<p><b>Te-Amo Business Solutions</b> was founded by Craig Naidoo in 2016 as a specialist services provider of retail IT Equipment And Security products in South Africa. Since then, the Company has developed into one of the leading suppliers of Retail IT and Security Solutions for a wide array of businesses as well as homes. growing in its confidence as a leading supplier, with the vision of providing Innovating Solutions for our Customers, offering the highest quality of service, saving the customer time and money in this ever changing, economically challenging Technological Environment.</p><br /><br />

	        	<p>Here at, <b>Te-Amo Business Solutions</b>, we pride ourselves in providing cost-effective and efficient solutions to our valued clientele. From CCTV, to PC Repairs, our dedicated Team is prepared to help you further your I.T requirements to the next level. We continually strive to exceed our customer's expectations.</p><br /><br />

	        	<p>Our strategic intention is to grow our customer base by offering <b>Innovating I.T Solutions</b> to our valued customers, through our highly skilled, highly motivated and competent staff, that are Customer Service driven. <b>Te-Amo Business Solutions</b> has grown from just a single owner technician to a staff contingent of 7 full time staff members which cover a host of Business avenues. From Administration, Marketing, Technical Engineers, Technical Sales, Gate Motor Specialists and a dedicated Web Development Engineer, our certified team will assist you with a full Business and Residential Setup & Support. We pride ourselves in our values namely, Customer Commitment, Passion & Pride, Teamwork and Quality.</p><br /><br />

	        	<p>Our company specializes in the distribution and installation of CCTV products, POS Systems, Networking, repairing of computers and website development and has since its inception, provided long term immediate support to its constantly growing database. With a host of clientele from Residential to Corporate Businesses, Primary, Secondary and Tertiary Institutes and not forgetting our social responsibilities and charities, we have a wide range of customers and our database is growing exponentially. Our competitiveness in the market has made us an Award-Winning Company and our existing clientele speaks for itself with exceptional recommendations and growing database.</p><br /><br />

	        	<p><b>Te-Amo Business Solutions</b> is a certified level 1 BBBEE Supplier, a valued member of the Durban Chamber of Commerce and a member of the Central Supplier Database.</p><br /><br />

	        	<p><b>Innovating I.T Solutions for your Needs.</b></p>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;