// components/ParticleBackground.js
import React, { useEffect, useState } from 'react';
import Particles from '@tsparticles/react';
import { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
//import particlesConfig from '../../config/particlesConfig';

const ParticleBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    const initParticles = async () => {
      await initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      });
      setInit(true);
    };

    initParticles();
  }, []);

  return init ? <Particles options={
        {
        fullScreen: {
          enable: false,
        },
      background: {
        color: {
          value: "transparent", // Set background to transparent
        },
      },
      fpsLimit: 60,
      particles: {
        color: {
          value: "#1b441c", // Green neon color
        },
        move: {
          direction: "top", // Move upwards
          enable: true,
          speed: 2, // Speed of the movement
        },
        number: {
          density: {
            enable: true,
            area: 800, // Density area
          },
          value: 50, // Number of particles
        },
        opacity: {
          value: 0.8, // Opacity of the particles
        },
        shape: {
          type: "circle", // Shape of the particles
        },
        shadow: {
          enable: true,
          color: "#39ff14", // Shadow color
          blur: 10, // Shadow blur
          offset: {
            x: 0,
            y: 0,
          },
        },
        size: {
          value: 5, // Size of the particles
        },
      },
      detectRetina: true,

  }} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} /> : null;
};

export default ParticleBackground;
