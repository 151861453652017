import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contact = () =>
{
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);
	return(
		<>
		<div className="about">
			<div className="container" >
											<Nav />

									        <div className="logoPagesCover">
												<img
										              className='logoPages'
										              src={Logo}
										              alt="Next.js Logo"
										              width={390}
										              height={164}
										            />
									        </div>

									        <div className="row">
									        	<div className="col-md-6">
									        		<h1>Kwazulu-Natal</h1><br />
									        		<h3>Address</h3>
									        		<b>2 Nelson Cres,<br /> Malvern,<br /> Queensburgh,<br /> 4055</b><br /><br />

									        		<h3>Email Us</h3>
									        		<p><FontAwesomeIcon icon={faEnvelope} /> help@te-amo.co.za<br /><FontAwesomeIcon icon={faEnvelope} /> info@te-amo.co.za<br /><FontAwesomeIcon icon={faEnvelope} /> support@te-amo.co.za</p>

									        		<h3>Call Us</h3>
									        		<p><FontAwesomeIcon icon={faPhone} /> 031 463 3124 (Store)<br /><FontAwesomeIcon icon={faMobileAlt} /> (076) 113 8995<br /><FontAwesomeIcon icon={faMobileAlt} /> (065) 001 8251 (Tech support)</p>
									        	</div>
									        	<div className="col-md-6">
									        		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.450616365752!2d30.923896974519252!3d-29.8801139236736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7aabcd2bb82bb%3A0xc7a92a163db79c2a!2sTE%20-AMO%20BUSINESS%20SOLUTIONS!5e0!3m2!1sen!2sza!4v1723727932337!5m2!1sen!2sza" allowfullscreen="" style={{border:0, width: '100%', height: '400px'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
									        	</div>
				</div>					        </div><br /><br /><br /><br />
			</div>
			<section className='topFooter'>
		        <div className="container">
		          <h1 className="text-center">Te-Amo Business Solutions</h1>

		          <div className="row">
		            <div className="col-md-4">
		              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
		            </div>
		            <div className="col-md-4">
		              <p><b>Email</b>: help@te-amo.co.za</p>
		              <p><b>Email</b>: info@te-amo.co.za</p>
		            </div>
		            <div className="col-md-4">
		              <p><b>Phone</b>: 031 463 3124</p>
		              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
		            </div>
		          </div>
		        </div>
		      </section>

		    <footer className='footer'>
		        <div className="container">
		            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
		        </div>
		      </footer>
		</>
	)
}

export default Contact;