import {Link} from 'react-router-dom';
const Nav = () => {

	const showNav = () => {
      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
	}

	const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
	}

	return(
	<>
		<nav className='nav' id="nav">
	        <span onClick={()=>hideNav()}>X</span>
	        <ul>
	          <li><Link to="/">Home</Link></li>
	          <li><Link to="/about">About</Link></li>
	          <li><Link to="/repairs">Repairs</Link></li>
	          <li><Link to="/cctv">CCTV</Link></li>
	          <li><Link to="/pos">POS</Link></li>
	          <li><Link to="/network">Network</Link></li>
	          <li><Link to="/software">Software</Link></li>
	          <li><Link to="/InvertersAndAutomations">Inverters & Gate Automations</Link></li>
	          {/*<li>Social</li>*/}
	          <li><Link to="/contact">Contact</Link></li>
	        </ul>
	    </nav>

	    <div className='menuBg' id="menuBg" onClick={()=>showNav()}>
	        <div className='menuBgTop' id="menuBgTop">
	        </div>
	        <div className='menuBgCenter' id="menuBgCenter">
	        </div>
	        <div className='menuBgBottom' id="menuBgBottom">
	        </div>
	    </div>
    </>
	);
}

export default Nav;