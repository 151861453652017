import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import Network1 from '../../imgs/Network/unnamed (1)2.jpg';
import Network2 from '../../imgs/Network/unnamed (1)2.png';
import Network3 from '../../imgs/Network/unnamed (2)2.jpg';
import Network4 from '../../imgs/Network/unnamed2.jpg';
import Network5 from '../../imgs/Network/unnamed (4).jpg';
import Network6 from '../../imgs/Network/unnamed (5).jpg';
import Network7 from '../../imgs/Network/unnamed2.png';
import Network8 from '../../imgs/Network/2.jpg';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>NETWORKING AND INFRASTRUCTURE</h1>
	        	<p>Your network is the cornerstone of your business, connecting employees across sites and countries. As networks grow the challenges grow increasingly more complex, increasing the number of users and the addition of new services eats up more of your precious bandwidth. We ensure your network is capable of meeting your business objectives.</p><br /><br />

	        	<p>We combine the hardware, expertise and services for your organization's needs to design and deploy secure networks built with your business objectives in mind. Our network services have been deployed across multiple networks in the private and public sector.</p><br /><br />

	        	<p>We deploy a variety of complicated network infrastructure services from switching and wireless to WAN applications and advanced network solutions.</p><br /><br />

	        	<p>To find out exactly what you need to move forward with your networking and infrastructure, simply give our support team a call or send us an email - We are happy to guide you toward success!</p><br /><br />

	        	<div className="row">
	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network1}
				              alt="Next.js Logo"
				            />
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network2">
		        			<img
				              src={Network2}
				              alt="Next.js Logo"
				            />
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network3}
				              alt="Next.js Logo"
				            />
				            <p>System Health Check</p>
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network4}
				              alt="Next.js Logo"
				            />
				            <p>Hardware repairs</p>
			            </div>
	        		</div>


	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network5}
				              alt="Next.js Logo"
				            />
				            <p>Boardroom setup</p>
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network2">
		        			<img
				              src={Network6}
				              alt="Next.js Logo"
				            />
				            <p>Cable & Wireless network</p>
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network7}
				              alt="Next.js Logo"
				            />
				            <p>Network Solutions & Installations</p>
			            </div>
	        		</div>
	        		<div className="col-md-4">
	        			<div className="network1">
		        			<img
				              src={Network8}
				              alt="Next.js Logo"
				            />
			            </div>
	        		</div>
	        	</div>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;