import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom';
import Home from './Components/Page/Home';
import About from './Components/Page/About';
import Repairs from './Components/Page/Repairs';
import CCTV from './Components/Page/cctv';
import POS from './Components/Page/pos';
import Network from './Components/Page/network';
import Software from './Components/Page/software';
import Inverter from './Components/Page/inverters';
import Vox from './Components/Page/vox';
import Shop from './Components/Page/shop';
import Catalogue from './Components/Page/catalog';
import Mobile from './Components/Page/mobile';
import Contact from './Components/Page/contact';
import Servic from './Components/Page/serviceDesk';
import Root from './Components/Page/Root';

export default function App() {

  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path="/" element={<Root />} >
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/repairs" element={<Repairs />} />
              <Route path="/cctv" element={<CCTV />} />
              <Route path="/pos" element={<POS />} />
              <Route path="/network" element={<Network />} />
              <Route path="/software" element={<Software />} />
              <Route path="/InvertersAndAutomations" element={<Inverter />} />
              <Route path="/Vox" element={<Vox />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/catalogue" element={<Catalogue />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/service/desk" element={<Servic />} />
              <Route path="/software/mobile" element={<Mobile />} />
          </Route>
      )
  );

  return (
        <>
          <RouterProvider router={router} />
        </>
  );
}
