import {useEffect, useState} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const About = () => {
	const [desk, setDesk] = useState(true);
	const [cp, setCp] = useState();

	const [company, setCompany] = useState('');
	const [fisrt, setFirst] = useState('');
	const [last, setLast] = useState('');
	const [priority, setPriority] = useState('none');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [type, setType] = useState('none');
	const [message, setMessage] = useState('');

const sub = async(e) => {
	e.preventDefault();

	console.log(fisrt, last, company, priority, phone, email, type, message);

	if(last === '' && priority === 'none' && phone === '' && email === '' && type === 'none' && message === '')
	{
		NotificationManager.error('Please fill all fields', 'Error');
	}
	else
	{
		const res = await fetch('https://api.te-amo.co.za/public/api/enquiries/add', {
		method: 'POST',
	    headers: {
	        'Content-Type': 'application/json',
	    },
	        body: JSON.stringify({'name':fisrt, 'lastname':last, 'company':company, 'priority':priority,  'phone':phone, 'email':email, 'type':type, 'message':message, 'id':'help@te-amo.co.za'}),
		});
		const data = await res.json();

		if(data.message == 'enquiry added')
		{
			setCompany('');
			setFirst('');
			setLast('');
			setPriority('none');
			setPhone('');
			setEmail('');
			setType('none');
			NotificationManager.success('Enquiry was submited.', 'Success');
		}
		//NotificationManager.success('Success message', 'Title here');
		//console.log(company, fisrt, last, priority, phone, email, type, message);
	}
}

  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <h1>Service desk</h1><br />
	        	{
					desk?
						<div className="service-form-mobile">
							<select class="form-select" aria-label="Default select example" onChange={(e)=>setCp(e.target.value)}>
							  <option selected hidden>Company or Personal</option>
							  <option value="Company">Company</option>
							  <option value="Personal">Personal</option>
							</select><br />
							{
								cp=="Company"?
								<form onSubmit={(e)=>sub(e)}>
								  <div className="row">
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="text" class="form-control mb-1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company name" value={company} onChange={(e)=>setCompany(e.target.value)} />
										</div>
								  	</div>
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name" value={last} onChange={(e)=>setLast(e.target.value)} />
										</div>
								  	</div>
								  </div><br />
								  <select class="form-select" aria-label="Default select example" onChange={(e)=>setPriority(e.target.value)} required>
									  <option selected value="Low" hidden>Priority?</option>
									  <option value="Low">Low</option>
									  <option value="Medium">Medium</option>
									  <option value="High">High</option>
								  </select><br />
								  <div className="row">
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="number" class="form-control mb-1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
										</div>
								  	</div>
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
										</div>
								  	</div>
								  </div><br />
								  <select class="form-select" aria-label="Default select example" onChange={(e)=>setType(e.target.value)}>
									  <option selected hidden>Type?</option>
									  <option value="CCTV">CCTV Assesment</option>
									  <option value="Access Controll">Access Controll</option>
									  <option value="Callout">Callout</option>
									  <option value="Networking">Networking</option>
									  <option value="Software Update">Software Update</option>
									  <option value="Electrical Backup Solutions">Electrical Backup Solutions</option>
									  <option value="Point Of Sale">Point Of Sale</option>
								  </select><br />

								  <div class="form-group">
								    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" onChange={(e)=>setMessage(e.target.value)}></textarea>
								  </div><br />
								  
								  <button type="submit" class="btn btn-success">Submit</button>
								</form>:(
										cp=="Personal"?
									<form onSubmit={(e)=>sub(e)}>
									  <div className="row">
									  	<div className="col-md-6">
									  		<div class="form-group">
											   <input type="text" class="form-control mb-1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name" value={fisrt} onChange={(e)=>setFirst(e.target.value)} />
											</div>
									  	</div>
									  	<div className="col-md-6">
									  		<div class="form-group">
											   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name" value={last} onChange={(e)=>setLast(e.target.value)} />
											</div>
									  	</div>
									  </div><br />
									  <select class="form-select" aria-label="Default select example" onChange={(e)=>setPriority(e.target.value)}>
										  <option selected value="Low" hidden>Priority?</option>
										  <option value="Low">Low</option>
										  <option value="Medium">Medium</option>
										  <option value="High">High</option>
									  </select><br />
									  <div className="row">
									  	<div className="col-md-6">
									  		<div class="form-group">
											   <input type="number" class="form-control mb-1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
											</div>
									  	</div>
									  	<div className="col-md-6">
									  		<div class="form-group">
											   <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
											</div>
									  	</div>
									  </div><br />
									  <select class="form-select" aria-label="Default select example" onChange={(e)=>setType(e.target.value)}>
										  <option selected hidden>Type?</option>
										  <option value="CCTV">CCTV Assesment</option>
										  <option value="Access Controll">Access Controll</option>
										  <option value="Callout">Callout</option>
										  <option value="Networking">Networking</option>
										  <option value="Software Update">Software Update</option>
										  <option value="Electrical Backup Solutions">Electrical Backup Solutions</option>
										  <option value="Point Of Sale">Point Of Sale</option>
									  </select><br />
									  
									  <div class="form-group">
									    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
									  </div><br />

									  <button type="submit" class="btn btn-success">Submit</button>
									</form>:''
								)
							}
						</div>
					:''
				}
	        
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;