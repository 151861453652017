import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';


import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

import catalog1 from '../../imgs/catalog/c2.png';
import catalog2 from '../../imgs/catalog/c3.png';
import catalog3 from '../../imgs/catalog/c4.png';
import catalog4 from '../../imgs/catalog/c5.png';
import catalog5 from '../../imgs/catalog/c6.png';
import catalog6 from '../../imgs/catalog/c7.png';
import catalog7 from '../../imgs/catalog/c8.png';
import catalog8 from '../../imgs/catalog/c9.png';
import catalog9 from '../../imgs/catalog/c10.png';
import catalog10 from '../../imgs/catalog/c11.png';
import catalog11 from '../../imgs/catalog/c12.png';
import catalog12 from '../../imgs/catalog/c13.png';
import catalog13 from '../../imgs/catalog/c14.png';
import catalog14 from '../../imgs/catalog/c15.png';
import catalog15 from '../../imgs/catalog/c16.png';
import catalog16 from '../../imgs/catalog/c17.png';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>Catalogue</h1>
	        	<SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
		            <img className="rounded w-small" src={catalog1} />
		            <img className="rounded w-small" src={catalog2} />  
		            <img className="rounded w-small" src={catalog3} />
		            <img className="rounded w-small" src={catalog4} />
		            <img className="rounded w-small" src={catalog5} />
		            <img className="rounded w-small" src={catalog6} />
		            <img className="rounded w-small" src={catalog7} />
		            <img className="rounded w-small" src={catalog8} />
		            <img className="rounded w-small" src={catalog9} />
		            <img className="rounded w-small" src={catalog10} />
		            <img className="rounded w-small" src={catalog11} />
		            <img className="rounded w-small" src={catalog12} />
		            <img className="rounded w-small" src={catalog13} />
		            <img className="rounded w-small" src={catalog14} />
		            <img className="rounded w-small" src={catalog15} />
		            <img className="rounded w-small" src={catalog16} />
		        </SlideshowLightbox>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;