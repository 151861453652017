import {useState} from 'react';
import { Outlet, Link } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Root = () => {
	const [desk, setDesk] = useState(false);
	const [cp, setCp] = useState();

	const [company, setCompany] = useState('');
	const [fisrt, setFirst] = useState('');
	const [last, setLast] = useState('');
	const [priority, setPriority] = useState('none');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [type, setType] = useState('none');
	const [message, setMessage] = useState('');

	const sub = async(e) => {
		e.preventDefault();

	console.log(fisrt, last, company, priority, phone, email, type, message);

	if(last === '' && priority === 'none' && phone === '' && email === '' && type === 'none' && message === '')
	{
		NotificationManager.error('Please fill all fields', 'Error');
	}
	else
	{
		const res = await fetch('https://api.te-amo.co.za/public/api/enquiries/add', {
		method: 'POST',
	    headers: {
	        'Content-Type': 'application/json',
	    },
	        body: JSON.stringify({'name':fisrt, 'lastname':last, 'company':company, 'priority':priority,  'phone':phone, 'email':email, 'type':type, 'message':message, 'id':'help@te-amo.co.za'}),
		});
		const data = await res.json();

		if(data.message == 'enquiry added')
		{
			setDesk(false);
			setCompany('');
			setFirst('');
			setLast('');
			setPriority('none');
			setPhone('');
			setEmail('');
			setType('none');
			NotificationManager.success('Enquiry was submited.', 'Success');
		}
		//NotificationManager.success('Success message', 'Title here');
		//console.log(company, fisrt, last, priority, phone, email, type, message);
	}
	}

	return(
		<>
			{
				desk?
				<div className="service-form-bg">
					<div className="service-form">
						<b className="close-service-form" onClick={()=>setDesk(false)}>X</b>
						<select class="form-select" aria-label="Default select example" onChange={(e)=>setCp(e.target.value)}>
						  <option selected hidden>Company or Personal</option>
						  <option value="Company">Company</option>
						  <option value="Personal">Personal</option>
						</select><br />
						{
							cp=="Company"?
							<form onSubmit={(e)=>sub(e)}>
							  <div className="row">
							  	<div className="col-md-6">
							  		<div class="form-group">
									   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company name" value={company} onChange={(e)=>setCompany(e.target.value)} />
									</div>
							  	</div>
							  	<div className="col-md-6">
							  		<div class="form-group">
									   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name" value={last} onChange={(e)=>setLast(e.target.value)} />
									</div>
							  	</div>
							  </div><br />
							  <select class="form-select" aria-label="Default select example" onChange={(e)=>setPriority(e.target.value)}>
								  <option selected hidden>Priority?</option>
								  <option value="Low">Low</option>
								  <option value="Medium">Medium</option>
								  <option value="High">High</option>
							  </select><br />
							  <div className="row">
							  	<div className="col-md-6">
							  		<div class="form-group">
									   <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
									</div>
							  	</div>
							  	<div className="col-md-6">
							  		<div class="form-group">
									   <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
									</div>
							  	</div>
							  </div><br />
							  <select class="form-select" aria-label="Default select example" onChange={(e)=>setType(e.target.value)}>
								  <option selected hidden>Type?</option>
								  <option value="Low">CCTV Assesment</option>
								  <option value="Medium">Access Controll</option>
								  <option value="High">Callout</option>
								  <option value="High">Networking</option>
								  <option value="High">Software Update</option>
								  <option value="High">Electrical Backup Solutions</option>
								  <option value="High">Point Of Sale</option>
							  </select><br />

							  <div class="form-group">
							    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" onChange={(e)=>setMessage(e.target.value)}></textarea>
							  </div><br />
							  
							  <button type="submit" class="btn btn-success">Submit</button>
							</form>:(
									cp=="Personal"?
								<form onSubmit={(e)=>sub(e)}>
								  <div className="row">
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name" value={fisrt} onChange={(e)=>setFirst(e.target.value)} />
										</div>
								  	</div>
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name" value={last} onChange={(e)=>setLast(e.target.value)} />
										</div>
								  	</div>
								  </div><br />
								  <select class="form-select" aria-label="Default select example" onChange={(e)=>setPriority(e.target.value)}>
									  <option selected hidden>Priority?</option>
									  <option value="Low">Low</option>
									  <option value="Medium">Medium</option>
									  <option value="High">High</option>
								  </select><br />
								  <div className="row">
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
										</div>
								  	</div>
								  	<div className="col-md-6">
								  		<div class="form-group">
										   <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
										</div>
								  	</div>
								  </div><br />
								  <select class="form-select" aria-label="Default select example" onChange={(e)=>setType(e.target.value)}>
									  <option selected hidden>Type?</option>
									  <option value="Low">CCTV Assesment</option>
									  <option value="Medium">Access Controll</option>
									  <option value="High">Callout</option>
									  <option value="High">Networking</option>
									  <option value="High">Software Update</option>
									  <option value="High">Electrical Backup Solutions</option>
									  <option value="High">Point Of Sale</option>
								  </select><br />
								  
								  <div class="form-group">
								    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
								  </div><br />

								  <button type="submit" class="btn btn-success">Submit</button>
								</form>:''
							)
						}
					</div>
				</div>
				:''
			}
			<Outlet />

			<div className="service-desk">
				<p onClick={()=>setDesk(true)} className="bg-success">Service desk</p>
			</div>

			<div className="service-desk-mobile">
				<Link to="/service/desk" ><p className="bg-success">Service desk</p></Link>
			</div>
			<NotificationContainer />
		</>
	)
}

export default Root;