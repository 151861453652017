import {useEffect} from 'react';
import { Outlet, Link } from 'react-router-dom';
import Nav from '../Nav';
import Logo from '../../imgs/TE-AMO-LOGO-2-(1).png';

import Pos1 from '../../imgs/pos/image014 (1).jpg';
import Pos2 from '../../imgs/pos/unnamed.png';
import Pos3 from '../../imgs/pos/image046.png';
import Pos4 from '../../imgs/pos/unnamed (3).jpg';

const About = () => {
	 const showNav = () => {

      const nav = document.getElementById('nav');
      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '0%';
      menuBgTop.style.left = '-5000%';
      menuBgBottom.style.left = '-5000%';
      menuBgCenter.style.left = '-5000%';
  }

  useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

  const hideNav = () => {
      const nav = document.getElementById('nav');

      const menuBgTop = document.getElementById('menuBgTop');
      const menuBgBottom = document.getElementById('menuBgBottom');
      const menuBgCenter = document.getElementById('menuBgCenter');

      nav.style.top = '-110%';
      menuBgTop.style.left = '-0%';
      menuBgBottom.style.left = '-0%';
      menuBgCenter.style.left = '-0%';
  }

	return(
		<>
		<div className="about">
			<Nav />

	        <div className="logoPagesCover">
				<img
		              className='logoPages'
		              src={Logo}
		              alt="Next.js Logo"
		              width={390}
		              height={164}
		            />
	        </div>

	        <div className="container">
	        	<h1>POINT OF SALE (POS) SYSTEMS</h1><br />
	        

		        <div>
		        	<div className="row">
		        		<div className="col-md-6">
		        			<img src={Pos1} />
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text">Point of Sale (POS) software, also known as electronic Point of Sale (ePOS), is an essential application for retail or hospitality businesses because it manages checkout operations and is what the customer sees when they make a transaction</p>
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text">POS software or POS systems are located wherever a transaction occurs, which generally tends to mean the terminal that is used for checkouts.</p>
		        		</div>
		        		<div className="col-md-6">
		        			<img src={Pos2} />
		        		</div>
		        		<div className="col-md-6">
		        			<img src={Pos3} />
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text3">Some systems are the equivalent of an electronic cash register, used to make and record a sale in a restaurant, cafe, retail store or supermarket, and also in hotels, stadiums and casinos.</p>
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text">But point of sale systems can also be used as part of a more sophisticated IT system, linked into back-office stock control, ordering, and customer relationship management (CRM) applications.</p>
		        		</div>
		        		<div className="col-md-6">
		        			<img src={Pos4} />
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text">LPOS systems are manufactured and serviced by a range of firms and tend to run on a range of operating systems, including DOS, Windows, Linux and Unix.</p>
		        		</div>
		        		<div className="col-md-6">
		        			<p className="pos-text">Preffered Installer for Robotill P.O.S. </p>
		        		</div>
		        	</div>
		        </div>
	        </div>
		</div>

		<section className='topFooter'>
	        <div className="container">
	          <h1 className="text-center">Te-Amo Business Solutions</h1>

	          <div className="row">
	            <div className="col-md-4">
	              <p>2 Nelson Cresent Malvern, Queensburgh 4055</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Email</b>: help@te-amo.co.za</p>
	              <p><b>Email</b>: info@te-amo.co.za</p>
	            </div>
	            <div className="col-md-4">
	              <p><b>Phone</b>: 031 463 3124</p>
	              <p><b>24 HR Tech Support</b>: 076 113 8995</p>
	            </div>
	          </div>
	        </div>
	      </section>

	      <footer className='footer'>
	        <div className="container">
	            <p>© Copyright <b>Te-Amo Business Solutions 2024</b>. All Rights Reserved</p>
	        </div>
	      </footer>
	    </>
	)
}

export default About;